<template>
  <nav>
      <li v-for="menu in menus" :key="menu.id">
        <router-link v-if="menu.link" :to="menu.link">{{menu.text}}</router-link>
      </li>  
      <li v-if="!isCartEmpty">
        <router-link to="/checkout">Checkout</router-link>
      </li>  
  </nav>
</template>

<script>
export default {
  name: 'SettingsNavigation',
  data() {
    return {
      menus: [
        {
          id: 0,
          text: 'My Account',
          link: '/account',
        },
        {
          id: 1,
          text: 'Password and Security',
          link: '/security',
        },
        {
          id: 2,
          text: 'Payment Methods',
          link: '/payment-methods',
        },
        {
          id: 3,
          text: 'Invoices',
          link: '/invoices',
        },
        // {
        //   id: 4,
        //   text: 'My Subscription',
        //   link: '/my-subscription',
        // }
      ]
    };
  },
  computed: {
    isCartEmpty(){
      if(localStorage.getItem('checkoutProduct')) return false;
      return true;
    },
    product(){
      return localStorage.getItem('checkoutProduct');
    }
  }
};
</script>

<style scoped>
section.content {
    display: grid;
    grid-template-columns: 200px 1fr;
}
nav.setting-nav li {
    text-align: left;
    list-style-type: none;
}
nav.setting-nav li:not(:nth-last-child(1)) {
    border-bottom: 1px solid #CCCCDB;
}
nav.setting-nav li a {
    color: #102445;
    text-decoration: none;
    padding: 11px 10px;
    display: block;
}
nav.setting-nav li a.router-link-exact-active {
    border-left: 4px solid #102445;
}
nav.setting-nav {
    border: 1px solid #CCCCDB;
    border-radius: 10px;
}
nav.setting-nav li a:hover {
    background-color: #CCCCDB;
}
</style>