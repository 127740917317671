<template>
  <div class="main-section" id="billing">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <div class="backdrop">
        <section>
          <h1>Billing</h1>
          <button class="signout" @click="signOut()">Sign out</button>
        </section>
        <section class='separator'></section>
        <section class="content">
          <section>
            <SettingsNavigation class="setting-nav"/>
          </section>
          <section class="billing-content">
            <AddNewCard 
              :additionalCard="newCard"
              :toggle="toggleAddCardForm" 
              v-if="!user.app.stripe_customer.sources || !user.app.stripe_customer.sources.data.filter(c=>c.object=='card').length || newCard"
            />
            <section v-if="user.app.stripe_customer.sources && user.app.stripe_customer.sources.data.filter(c=>c.object=='card').length && !newCard" class="payment-methods">
              <article v-for="source in user.app.stripe_customer.sources.data.filter(c=>c.object=='card')" :key="source.id" class="source-card" :class="{default: isDefaultCard(source.id)}">
                <img :src="cardIcons[source.brand]" :alt="source.brand">
                <section>
                  <p>{{source.name}}</p>
                  <p>{{source.last4}}</p>
                  <p>{{source.exp_month}}/{{source.exp_year}}</p>
                </section>
                <br>
                <article class="card-actions">
                  <a href="javascript:;" v-if="!isDefaultCard(source.id)" @click="setDefaultMethod(source.id)">Set as default</a>
                  <a href="javascript:;">Update</a>
                  <a 
                    v-if="!isDefaultCard(source.id)"
                    href="javascript:;"
                    @click.prevent
                    v-confirm="{
                      loader:false,
                      ok: ()=>deleteSource(source.id),
                      message: `Are you sure you want to delete the payment method ending in ${source.last4}? This cannot be undone.`
                    }">
                    Delete
                  </a>
                </article>
              </article>
              <article class="source-card add-new" @click="toggleAddCardForm">
                <span>
                  <fa-icon class="add-new-icon" :icon="['fas','plus']" />
                  <p>Add New</p>
                </span>
              </article>
            </section>
            <section class="loading-section" v-if="loading">
              <Loading />
            </section>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VuejsDialog from 'vuejs-dialog';
import Background from '../components/Background/Background';
import { mapActions, mapGetters } from 'vuex';
import SettingsNavigation from '../components/SettingsNavigation';
import API from '../utils/api';
import visa from 'payment-icons/svg/flat/visa.svg';
import amex from 'payment-icons/svg/flat/amex.svg';
import diners from 'payment-icons/svg/flat/diners.svg';
import discover from 'payment-icons/svg/flat/discover.svg';
import jcb from 'payment-icons/svg/flat/jcb.svg';
import mastercard from 'payment-icons/svg/flat/mastercard.svg';
import unionpay from 'payment-icons/svg/flat/unionpay.svg';
import unknown from 'payment-icons/svg/flat/default.svg';
import Loading from '../components/Loading';
import '../icons';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import toast from '../plugins/toast';
import AddNewCard from '../components/AddNewCard';

Vue.use(VuejsDialog, {
  html: true,
  loader: true,
  okText: 'Proceed',
  cancelText: 'Cancel',
  animation: 'bounce'
});

const cardIcons = {
  'Visa': visa,
  'American Express': amex,
  'Diners Club': diners,
  'Discover': discover,
  'JCB': jcb,
  'MasterCard': mastercard,
  'UnionPay': unionpay,
  'Unknown': unknown,
};

export default {
  name: 'Billing',
  components: {
    Background,
    SettingsNavigation,
    Loading,
    AddNewCard
  },
  data(){
    return {
        backgroundSrc: 'Billing.svg',
        submitError: null,
        newCard: false,
        cardIcons,
        loading: false
    };
  },
  methods: {
    ...mapGetters('user',['getUser']),
    ...mapActions('user',['signOut', 'setAppUser']),
    toggleAddCardForm(){
      this.newCard = !this.newCard;
    },
    isDefaultCard(id){
      return this.user.app.stripe_customer.default_source == id;
    },
    async setDefaultMethod(card){
      try {
        this.loading = true;
        let { data: user } = await API.put('billing/method/default', {source:card});
        this.setAppUser(user);
        this.loading = false;
        toast.show('Your default payment method was updated successfully.', {type:'success'});
      } catch(err){
        this.loading = false;
        toast.show('We encountered an issue while updating your default payment method.', {type:'error'});
      }
    },
    async deleteSource(sourceID){
      try {
        this.loading = true;
        let { data: user } = await API.delete(`billing/method/${sourceID}`);
        this.setAppUser(user);
        this.loading = false;
        toast.show('Payment method removed successfully.', {type:'success'});
      } catch(error){
        toast.show('We encountered an issue while removing the payment method.', {type:'error'});
        this.loading = false;
        console.error(error.message);
      }
    }
  },
  computed:{
    user(){
      return this.getUser();
    }
  }
};
</script>

<style scoped>
form{
  display: grid;
  grid-gap: 10px;
}
.content{
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 20px;
  /* grid-template-rows: minmax(auto, 600px); */
}
.backdrop{
  max-height:unset;
  height: unset;
}
.backdrop > section:nth-child(1){
  display:grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
}
.signout{
  width: 110px;
  background-color: #102445;
  border-radius: 50px;
  text-transform: uppercase;
  color: #6E7174;
  border: unset;
  font-size: 13px;
  height: 35px;
  font-weight: 500;
  cursor: pointer;
  color: #FFF;
}
section.payment-methods {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    grid-template-rows: 130px;
    /* overflow-x: scroll; */
}
article.source-card{
    border: 1px solid #ccccdb;
    padding: 15px;
    border-radius: 10px;
    display: grid;
    align-items: center;
    grid-template-columns: minmax(30%, 130px) 1fr;
    text-align: left;
    grid-gap: 0 20px;
    overflow: hidden;
    position: relative;
    height: 130px;
    /* cursor: pointer; */
}
article.source-card img {
    width: 100%;
}
article.source-card section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    width: fit-content;
}
article.source-card section p:nth-child(1) {
    grid-column: span 2;
    font-size: 21px;
    font-weight: 700;
    top: 18px;
    position: relative;
}
article.source-card section p:not(:nth-child(1)) {
    position: relative;
    top: 2px;
}
article.source-card section p:nth-last-child(1) {
    text-align: right;
}
article.source-card.add-new {
    grid-template-columns: 1fr !important;
    text-align: center;
    color: #ccccdb;
    font-weight: 600;
    cursor: pointer;
}
article.source-card.default:after {
    display: block;
    width: 30px;
    height: 70px;
    background-color: #fd5c02;
    content: '';
    position: absolute;
    top: -27px;
    right: 0;
    transform: rotate(-45deg);
}
.add-new-icon {
    font-size: 50px;
    color: #ccccdb;
    margin-bottom: 14px;
}
nav.setting-nav {
    position: sticky;
    top: 10px;
    overflow: hidden;
}
section.loading-section {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFF;
}
section.billing-content {
    position: relative;
}
article.card-actions {
    text-align: right;
}

article.card-actions a {
    margin-left: 10px;
    text-decoration: none;
    color: #102545;
    font-size: 12px;
    font-weight: 600;
}

article.card-actions a:nth-last-child(1):not(:nth-child(1)) {
    color: #cc3838;
}
</style>