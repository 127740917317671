<template>
  <div class="signup">
    <form action="" v-on:submit.prevent="signUp">
    <div id="signup-top-section">
        <h3>Start your free trial!</h3>
        <section id="signup-providers">
          <img @click="signInFacebook()" :src="icons.FacebookLogin" alt="Facebook Signup">
          <img @click="signInTwitter()" :src="icons.TwitterLogin" alt="Twitter Signup">
          <img @click="signInGoogle()" :src="icons.GoogleLogin" alt="Google Signup">
          <!-- <img src="../assets/social/LinkedInLogin.svg" alt="LinkedIn Login"> -->
        </section>
        <p>or signup with your email address</p>
    </div>
    <div class="error" v-if="getError()">
      <p v-html="getError().message"></p>
    </div>
    <div id="signup-fields">
        <input v-model="user.name" type="text" name="name" placeholder="First and Last Name">
        <input v-model="user.email" type="text" name="email" placeholder="Email">
        <input v-model="user.password" type="password" name="password" placeholder="Password">
        <input v-model="user.confirmPassword" type="password" name="confirm-password" placeholder="Password">
    </div>
    <div id="signup-buttons">
        <button type="submit">Sign Up</button>
    </div>
    </form>
    <p class="login-link">Already have an account? <router-link to="/login">Log in</router-link></p>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FacebookLogin from '@/assets/Social/FacebookLogin.svg';
import TwitterLogin from '@/assets/Social/TwitterLogin.svg';
import GoogleLogin from '@/assets/Social/GoogleLogin.svg';
import Joi from 'joi';
export default {
  data(){
    return {
      icons: {
        FacebookLogin,
        TwitterLogin,
        GoogleLogin
      },
      user: {
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
      }
    };
  },
  methods: {
    ...mapActions('user', ['signInGoogle','signInFacebook','signInTwitter','signUpEmail','setError']),
    ...mapGetters('user', ['getError']),
    signUp() {
      let schema = Joi.object({
        name: Joi.string().regex(/^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/i).required().messages({
          'string.pattern.base': 'Must provide a first and last name',
          'string.pattern.invert.base': 'Must provide a first and last name',
          'string.pattern.invert.name': 'Must provide a first and last name',
          'string.pattern.name': 'Must provide a first and last name',
          'string.empty': 'Must provide a first and last name'
        }),
        email: Joi.string().email({tlds:{allow:false}}).messages({
                        'string.email': "You've provided an invalid email",
                        'string.empty': 'Email is required'
                      }),
        password: Joi.string().min(8).regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/).messages({
          'string.empty': 'Password is required',
          'string.min': 'Password must be at least 8 characters long',
          'string.pattern.base': 'Password must contain 1 lowercase, 1 uppercase, 1 number, and 1 special character.',
          'string.pattern.invert.base': 'Password must contain 1 lowercase, 1 uppercase, 1 number, and 1 special character.',
          'string.pattern.invert.name': 'Password must contain 1 lowercase, 1 uppercase, 1 number, and 1 special character.',
          'string.pattern.name': 'Password must contain 1 lowercase, 1 uppercase, 1 number, and 1 special character.',
        }),
        confirmPassword: Joi.any().valid(Joi.ref('password')).required().messages({
          'any.only': 'Passwords do not match'
        })
      });

      let validation = schema.validate({
        ...this.user
      },
      {abortEarly:false});

      if(validation.error){
        this.setError({
          message: validation.error.details.reduce((error, item)=>{
            return `${error}<li>${item.message}</li>`;
          }, '')
        });
      } else {
        this.signUpEmail(this.user);
      }

    },
    parseError(){
      
    }
  }
};
</script>

<style>
#page-content{
  height:100%
}
.signup {
    max-width: 500px;
    width:100%;
    min-width:300px;
    min-height: 520px;
    margin: 0 auto;
    background-color: #FFF;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 80px;
    transform: translateY(-50%);
    box-shadow: 0 5px 35px rgba(0,0,0,.1);
    margin-bottom: 20px;
    padding-bottom: 20px;
}
@media (max-height:700px){
  .signup {
    transform: unset !important;
    top: 30px;
  }
  #signup {
    min-height: 550px;
  }
}
#signup-folder-icon {
  text-align: left;
}

#signup-top-section {
    color: #222222;
    padding-top: 50px;
    margin-bottom:35px;
}

#signup-top-section h3 {
  font-size:35px;
}

#signup-providers img{
  margin:10px 7px;
  cursor: pointer;
}

#signup-top-section p{
  font-size:18px;
  color:#777777;
}

#signup-fields > *{
  display:block;
  width:80%;
  margin:20px auto;
  height:46px;
  background-color:#F1F2F6;
  border-radius:5px;
  border:unset;
  padding:20px;
  font-family: ProximaNova;
  font-size: 15px;
}
#signup-fields > *::placeholder{
  color:#AAAAAA;
}

#forgotpwd-link{
  font-size:18px;
  color:#222222;
  display: block;
  text-align: center;
  text-decoration: none;
  font-weight:600;
  margin-bottom:30px;
  margin-top:30px;
}

#signup-buttons button{
  width:180px;
  background-color:#102445;
  border-radius:50px;
  text-transform: uppercase;
  color:#FFF;
  border:unset;
  font-size: 15px;
  height:50px;
  font-weight: 600;
  cursor: pointer;
}

.error {
    background-color: #ba2304;
    margin: -20px auto 0;
    width: 80%;
    padding: 12px;
    color: #FFF;
    border-radius: 5px;
    font-weight: 600;
    line-height: 21px;
    word-break: break-all;
    text-align: left;
}
p.login-link {
    margin-top: 18px;
}
p.login-link a {
    color: #d47867;
}
</style>