<template>
  <div id="about" class="main-section">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <div class="backdrop" :class="{loading}">
        <section v-if="!loading">
          <section id="upgrade-content">
            <h1 id="title">Get more out of Anyfile</h1>
              <p>Send files with no size limit, receive, edit, and delete with our file manager.</p>
          </section>
          <section id="plans">
            <article v-for="plan in plans" :key="plan.product_id">
              <h3 v-html="plan.title"></h3>
              <h4 v-html="plan.price"></h4>
              <ul>
                <li v-for="(highlight, index) in plan.highlights" :key="index"><img :src="require(`@/assets/Upgrade/${highlight.icon}`)"> {{highlight.text}}</li>
              </ul>
              <button 
                @click="()=>routeRequest(plan.product_id)" 
                :class="{inverted: plan.button.style == 'inverted', disabled: isButtonDisabled(plan)}">
                {{getButtonLabel(plan)}}
              </button>
            </article>
          </section>
        </section>
        <Loading v-else/>
        <section v-if="cancelOn && !loading" id="cancel-banner">
          Your subscription is set to be cancelled on {{cancelOn}}
          <button id="resume-subscription" @click="resumeSubscription">Resume Subscription</button>
          </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Background from '../components/Background/Background';
import Loading from '../components/Loading';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import moment from 'moment';
Vue.use(VuejsDialog, {
  html: true,
  loader: false,
  okText: 'Proceed',
  cancelText: 'Dismiss',
  animation: 'bounce'
});
// eslint-disable-next-line no-unused-vars
import api from '../utils/api';

export default {
  name: 'Upgrade',
  components: {
    Background,
    Loading
  },
  data(){ 
    return {
        ...mapGetters('plans', ['getPlans']),
        ...mapGetters('user',['getUser']),
        backgroundSrc: 'Upgrade.svg',
        loading: false, 
    };
  },
  methods: {
    ...mapGetters('user', ['isAuth','getSubscription']),
    ...mapActions('user',['setAppUser']),
    routeRequest(id){
      if(id=='free' && this.isAuth()) return this.cancelPlan();
      if(id=='free') return this.$router.push('/login');
      if(this.subscription && this.subscription.items.data[0].plan.product == id) return;
      if(this.subscription) return this.$router.push(`/my-subscription?switch=${id}`);
      localStorage.setItem('checkoutProduct', id);
      this.$router.push('/login?redirect=/checkout');
    },
    async cancelPlan(){
      if(this.subscription){
        await this.$dialog.confirm('Are you sure you wish to cancel your Anyfile subscription?')
        .then(()=>{
          this.loading = true;
          return api.delete(`billing/subscriptions/${this.subscription.id}`);
        })
        .then(r=>{
          this.setAppUser(r.data);
        })
        .catch((err)=>{
          console.error(err);
        });
        this.loading = false;
        return;
      } else {
        return;
      }
    },
    async resumeSubscription(){
      await this.$dialog.confirm('Are you sure you wish to continue your subscription to Anyfile?')
      .then(()=>{
        this.loading = true;
        return api.put(`billing/subscriptions/${this.subscription.id}`, {
          cancel_at_period_end: false
        });
      })
      .then(r=>{
        this.setAppUser(r.data);
      })
      .catch(err=>{
        console.error(err);
      });
      this.loading = false;
      return;
    },
    freePlanActive(id){
      return (id=='free'&&this.isAuth());
    },
    paidPlanActive(id){
      return (this.subscription && this.subscription.items.data[0].plan.product == id);
    },
    getButtonLabel(plan){
      let label = plan.button.text;
      if(this.freePlanActive(plan.product_id) && this.subscription) label = 'Downgrade to free';
      if(this.freePlanActive(plan.product_id) && !this.subscription) label = 'Current Plan';
      if(plan.trial_available && this.user?.app.trial_available) label = '14 day free trial'; 
      if(this.paidPlanActive(plan.product_id)) label = 'Current Plan';
      return label;
    },
    isButtonDisabled(plan){
      return (this.freePlanActive(plan.product_id) && !this.subscription) || this.paidPlanActive(plan.product_id);
    }
  },
  computed: {
    user(){
      return this.getUser();
    },
    subscription(){
      return this.getSubscription();
    },
    plans(){
      return this.getPlans();
    },
    cancelOn(){
      if(this.subscription && this.subscription.cancel_at_period_end){
        return moment.unix(this.subscription.cancel_at).format('LLLL');
      }
      return false;
    }
  }
};
</script>

<style scoped>
.backdrop{
  max-height: unset;
  min-height: 640px;
  height: unset;
}
.backdrop.loading{
  height:0;
}
#upgrade-content{
  text-align: center;
  max-width:800px;
  margin:0 auto;
}
#title{
  text-align: center;
}
p{
  color:#6E7174;
  line-height: 29px;
  margin-top:10px
}
#plans{
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(390px, calc(100% + 30px));
  grid-gap: 40px;
  height: calc(100% - 160px);
}
#plans > article{
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 0 25px rgba(0,0,0,0.07);
  padding: 30px;
  position:relative;
}
#plans > article > h3{
  margin-bottom: 5px;
  font-size: 25px
}
#plans > article > button{
  all: unset;
  text-align: center;
  display: block;
  background-color: #0f2748;
  color: #fff !important;
  padding: 15px 30px;
  border-radius: 50px;
  font-weight: 600 !important;
  border: 2px solid;
  text-transform: uppercase;
  width: calc(100% - 130px);
  bottom: 30px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  cursor: pointer;
}
#plans > article > button.disabled{
  cursor: not-allowed;
}
#plans > article > button.inverted{
  color:#0f2748 !important;
  border-color:#0f2748;
  background-color:#FFF;
}
#plans > article > ul {
  margin-top:20px;
}
#plans > article > ul > li{
  position:relative;
  list-style-type: none;
  padding: 6px 20px 6px 30px;
}
#plans > article > ul > li img {
    position: absolute;
    left: 3px;
    top: 8px;
    width: 15px;
}
#plans > article >>> h4 .prefix {
    color:#85888b;
    margin-right:5px;
}
#plans > article >>> h4 .value {
    font-size: 25px;
}
#plans > article >>> h4 .currency {
    font-size: 9px;
    color: #85888b;
    top: -10px;
    position: relative;
    margin-left: 1px;
}
#plans > article >>> h4 .term {
    font-size: 14px;
    color: #85888b;
    position: relative;
    left: -2px;
}
#plans > article >>> h4 .spacer{
  margin-bottom: 30px;
  display: block;
}
section#cancel-banner {
  background-color: #ec5050;
  color: #FFF;
  padding: 20px;
  font-size: 18px;
  position: relative;
  top: 20px;
  border-radius: 10px;
}
button#resume-subscription {
  padding: 7px;
  margin-left: 10px;
  position: relative;
  top: -1px;
  border: unset;
  border-radius: 5px;
  background-color: #FFF;
  color: #0f2748;
  cursor: pointer;
}
</style>