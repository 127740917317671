<template>
    <section class="transfer-actions">
        <span v-if="showLink=='true'" @click="copyURL(transfer.url)">
            <input class="share-link" type="text" disabled :value="transfer.url">
            <button class="copy-button">Copy Link</button>
        </span>
        <span :class="{'f-right':showLink=='true'}">
            <span v-if="showPassword && transfer.password" class="action password"  @click="copyPassword(transfer.password)">
                <!-- <router-link :to="`transfers/${transfer.id}`"> -->
                    <fa-icon class="action-icon" :icon="['fas','key']" />
                    {{transfer.password}}
                <!-- </router-link> -->
            </span>
            <span v-if="showDownload" class="action download"  @click="transferAction('download')">
                <!-- <router-link :to="`transfers/${transfer.id}`"> -->
                    <fa-icon class="action-icon" :icon="['fas','download']" />
                    Download
                <!-- </router-link> -->
            </span>
            <span class="action" v-if="showLink!='true'" @click="copyURL(transfer.url)">
                <fa-icon class="action-icon" :icon="['fas','link']" />
                Copy Link
            </span>
            <span class="action">
                <v-popover class="forward">
                    <span class="view-file-details">
                        <fa-icon class="action-icon" :icon="['fas','trash-alt']" />
                        Delete
                    </span>
                    <section slot="popover" class="forward-form">
                        <section class="notification-container">
                            <toggle-button v-model="deleteT.includeFiles"/>
                            <label>Delete files too?</label>
                        </section>
                        <button id="forward-submit-btn" @click="transferAction('delete')">Delete</button>
                    </section>
                </v-popover>
            </span>
        </span>
    </section>
</template>

<script>
import '../icons';
import { mapActions } from 'vuex';
import file from '../utils/file';
import toast from '../plugins/toast';
import Vue from 'vue';
import VTooltip from 'v-tooltip';
import API from '../utils/api';
import Joi from 'joi';
import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton);
Vue.use(VTooltip);

export default {
    name: 'TransferActions',
    props: ['transfer','showLink','showDownload','showPassword', 'updateTransfer'],
    data() {
        return {
            forward: {
                to:'',
                message:'',
                notification: false
            },
            deleteT: {
                includeFiles: false
            }
        };
    },
    methods: {
        ...mapActions('user', ['getToken']),
        ...mapActions(['removeTransfer']),
        async transferAction(type){
            switch(type){
                case 'download': {
                    if(this.transfer.files.length == 1) await file.downloadSingle(this.transfer.files[0], this.transfer.id, ()=>{});
                    else file.downloadBundle(this.transfer);
                    break;
                }
                case 'delete':
                    this.deleteTransfer();
                    break;
                default:
                    console.log('Do nothing');
                    break;
            }
        },
        copyURL(text) {
            let dummy = document.createElement('textarea');
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand('copy');
            document.body.removeChild(dummy);
            console.log('Link copied');
            toast.show('Link copied to clipboard',{type:'success'});
        },
        copyPassword(text) {
            let dummy = document.createElement('textarea');
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand('copy');
            document.body.removeChild(dummy);
            toast.show('Password copied to clipboard',{type:'success'});
        },
        deleteTransfer(){
            let schema = Joi.object({
                includeFiles: Joi.boolean().required()
            });
            let validation = schema.validate(this.deleteT);
            if(validation.error){
                let errors = {};
                validation.error.details.forEach(e=>{
                errors[e.path[0]] = e.message;
                });
                this.deleteErrors = errors;
                console.log(errors);
                return;
            } else {
                this.deleteErrors = {};
            }

            API.delete(`transfers/${this.transfer.id}?includeFiles=${this.deleteT.includeFiles}`)
            .then(() => {
                document.body.click();
                this.removeTransfer({id:this.transfer.id});
                if(this.$router.currentRoute.path !== '/transfers') this.$router.push('/transfers');
                if(this.deleteT.includeFiles){
                    toast.show('Transfer and files deleted successfully', {type:'success'});
                } else {
                    toast.show('Transfer deleted successfully. The file in this transfer have not been deleted', {type:'success'});
                }
            })
            .catch(err=>{
                document.body.click();
                toast.show(`Problem deleting transfer: ${(err.response?.data?.message || err.message)}`,{type:'error'});
            });
        }
    },
};
</script>

<style scoped>
.transfer-actions{
  margin-top:15px;
}
.transfer-actions .action{
  font-size: 12px;
  margin-right:20px;
  cursor: pointer;
}
.transfer-actions .action:nth-last-child(1){
    margin-right:0;
}
.copy-button{
  width: 110px;
  background-color: #102445;
  border-radius: 50px;
  text-transform: uppercase;
  color: #6E7174;
  border: unset;
  font-size: 13px;
  height: 35px;
  font-weight: 500;
  cursor: pointer;
  color: #FFF;
}
.share-link{
    padding-left: 15px;
    height: 35px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    width: 250px;
    border: 1px solid #CCCCDB;
    margin-right: -50px;
    font-size: 12px;
}
.f-right{
    float:right;
    margin-top:6px;
}
.action.download a{
    color: inherit !important;
    text-decoration: none;
}
.action.password {
    border: 1px solid;
    padding: 10px 5px;
    padding-right: 10px;
    border-radius: 20px;
}
.v-popover.forward {
    display: inline-block;
}
section.forward-form {
    padding: 10px;
    font-family: ProximaNova, Helvetica, Arial, sans-serif !important;
}

input#forward-email, textarea#forward-message {
    width: 100%;
    padding: 10px;
    font-size: 14px !important;
    margin-bottom: 15px;
    border: 1px solid #cdcddb;
    font-family: ProximaNova, Helvetica, Arial, sans-serif !important;
}

textarea#forward-message {
    height: 110px;
}

button#forward-submit-btn {
    width: 100%;
    padding: 11px;
    font-size: 13px;
    text-transform: uppercase;
    border-radius: 20px;
    border: unset;
    background-color: #112545;
    color: #FFF;
    font-weight: 100;
    cursor: pointer;
}
input#forward-email::placeholder, textarea#forward-message::placeholder{
    font-size:15px !important
}
section.notification-container {
    text-align: left;
    background-color: unset !important;
    padding: 0 !important;
    margin-right: 10px;
    margin-bottom: 15px;
}
.notification-container .vue-js-switch {
    margin-right: 10px;
}
</style>