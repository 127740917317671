<template>
  <section>
        <header class="section-header">
            Saved Payment Options
        </header>
        <article class="card-item" v-for="card in cards" :key="card.id">
            <span>
                <span class="option-container">           
                    <input 
                        type="radio" 
                        class="card-item"
                        name="card-item"
                        :value="card.id"
                        ref="selectedCard" 
                        v-model="selectedCard"> 
                    <span class="checked-indicator"></span>
                </span>
                <img class="dropdown-icon" :src="cardIcons[card.brand]">
                {{card.label}}
            </span>
        </article>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import visa from 'payment-icons/svg/flat/visa.svg';
import amex from 'payment-icons/svg/flat/amex.svg';
import diners from 'payment-icons/svg/flat/diners.svg';
import discover from 'payment-icons/svg/flat/discover.svg';
import jcb from 'payment-icons/svg/flat/jcb.svg';
import mastercard from 'payment-icons/svg/flat/mastercard.svg';
import unionpay from 'payment-icons/svg/flat/unionpay.svg';
import unknown from 'payment-icons/svg/flat/default.svg';
import newcard from '../assets/Misc/newcard.png';

const cardIcons = {
  'Visa': visa,
  'American Express': amex,
  'Diners Club': diners,
  'Discover': discover,
  'JCB': jcb,
  'MasterCard': mastercard,
  'UnionPay': unionpay,
  'Unknown': unknown,
  'newcard': newcard
};

export default {
    props: ['value'],
    data(){
        return {
            enableAdd:true,
            cardIcons,
            selectedCard: null
        };
    },
    watch: {
        selectedCard() {
            this.$emit('input', this.selectedCard);
            this.$emit('change', this.selectedCard, this.$refs['selectedCard']);
        }
    },
    methods: {
        ...mapGetters('user',['getUser']),
        log(e){
            console.log(e);
        },
        capitalize(word){
            let tmp = Array.from(word);
            return tmp.reduce((capitalized, letter, i)=>{
                if(i==0) letter = letter.toUpperCase();
                return capitalized+letter;
            }, '');
        },
    },
    computed: {
        cards(){
            console.count('card-selector');
            let user = this.getUser();
            let c = user.app?.stripe_customer.sources.data || [];
            // Only use card sources
            c = c.filter(s=>s.object=='card');
            c = c.map(ca=>{ca.label = `${ca.brand} Ending in **** ${ca.last4} | Expires ${ca.exp_month}/${ca.exp_year.toString().slice(-2)}`; return ca;});
            if(this.enableAdd && !c.find(c=>c.id=='add-card')) c.push({id:'add-card',label:'Add new payment method',brand: 'newcard'});
            return c;
        },
    },
    created(){
        this.selectedCard = this.value;
    }
};
</script>

<style scoped>
.dropdown-icon{
    width:50px !important;
    position:relative;
    top:9px;
    margin-right:5px;
    margin-left:10px;
}

input[type="radio"] {
    all: unset;
    width: 18px;
    height: 18px;
    border: 2px solid #CCCCDB;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left:0;
    z-index:1;
    top: -7px;
}
input[type="radio"]:checked ~ span.checked-indicator:after {
    width: 12px;
    height: 12px;
    display: block;
    background: #ccccdb;
    content: '';
    border-radius: 50%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}
span.checked-indicator {
    all: unset;
    width: 18px;
    height: 18px;
    border: 2px solid #CCCCDB;
    display: inline-block;
    border-radius: 50%;
    position: relative;
}
span.option-container {
    position: relative;
    top:4px;
}
.card-selector{
  margin-top: 10px;
  border: 1px solid #ececec;
  border-radius: 5px;
}
header.section-header {
    padding: 20px;
    font-size: 20px;
    font-weight: 600;
    background-color: #f5f6f8;
}
article.card-item {
    padding: 20px;
}
article.card-item:not(:nth-last-child(1)) {
    border-bottom: 1px solid #ececec;
}
article.card-item > span {
    position: relative;
    top: -6px;
    font-size: 17px;
}
</style>