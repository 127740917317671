<template>
  <section id="no-found-container">
        <img src="@/assets/gray-folder.svg" alt="Folder Icon">
        <p>No {{transferType}} Found</p>
  </section>
</template>

<script>
export default {
    name: 'NoTransfersFound',
    props: ['type'],
    computed: {
        transferType: function(){
            let label;
            switch(this.type){
                case 'sent':
                    label = 'Sent Transfers';
                    break;
                case 'received':
                    label = 'Received Transfers';
                    break;
                default:
                    label = 'Transfers';
                    break;
            }
            return label;
        }
    }
};
</script>

<style>
section#no-found-container {
    text-align: center;
}
section#no-found-container img {
    opacity: .5;
    margin-top: 15px;
}
section#no-found-container p {
    font-size: 20px;
    color: #ccc;
    margin-top: 15px;
}
</style>