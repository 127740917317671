<template>
    <section class="file-actions">
        <fa-icon v-for="action in actions" class="action-icon" :key="action" :icon="['fas',icons[action]]" @click="fileAction({type:action,file,transferID})"/>
    </section>
</template>

<script>
import '../icons';
import fileUtils from '../utils/file';
export default {
    name: 'FileActions',
    props: ['file','actions','transferID','updateTransfer'],
    data(){
        return {
            icons: {
                download:'download',
                preview:'eye'
            }
        };
    },
    methods: {
        async fileAction({type, file, transferID}){
            switch(type){
                case 'download':
                    await fileUtils.downloadSingle(file, transferID, this.updateTransfer);
                    break;
                case 'preview':
                    console.log('previewing');
                    break;
                default:
                    console.log('Do nothing');
                    break;
            }
        },
    }
};
</script>

<style>
.file-actions {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.action-icon{
    margin-left:10px;
    cursor: pointer;
}
</style>