<template>
  <div id="login" class="main-section">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <LoginForm />
    </div>
  </div>
</template>

<script>
import Background from '../components/Background/Background';
import LoginForm from '../components/LoginForm';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Login',
  components: {
    Background,
    LoginForm
  },
  data(){
    return {
        backgroundSrc: 'Login.svg'
    };
  },
  methods: {
    ...mapActions('user', ['signInGoogle']),
    ...mapGetters('user', ['isAuth'])
  }
};
</script>

<style>

</style>