<template>
  <div id="404" class="main-section">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <div class="backdrop">
        <section id="inner-container">
            <h1>404</h1>
            <p>Sorry, page not found</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Background from '../components/Background/Background';

export default {
  name: 'error404',
  components: {
      Background
  },
  data(){
      return {
            backgroundSrc: 'Misc.svg',
      };
  }
};
</script>

<style scoped>
.backdrop {
    box-shadow: 0 0 10px rgba(0,0,0,0.08);
    position:relative;
}
.backdrop h1 {
    font-size: 250px;
    text-align: center;
    line-height: 250px;
}
.backdrop p {
    font-size: 37px;
    margin-top: -26px;
}
#inner-container{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -55%);
}
</style>