<template>
  <div class="main-section" id="billing">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <div class="backdrop">
        <section>
          <h1>Checkout</h1>
          <button class="signout" @click="signOut()">Sign out</button>
        </section>
        <section class='separator'></section>
        <section class="content">
          <section>
            <SettingsNavigation class="setting-nav"/>
          </section>
          <section class="checkout-content">
            <section v-if="successfullySubscribed === true">
              <section id="checkout-success">
                <img :src="require('@/assets/Checkout/congratulations.svg')">
                <h3>Congratulations!</h3>
                <p>We are so glad you have decided to join Anyfile and allow us to fulfill your file sharing needs! Keep an eye out for the tour icon () as you navigate the platform. It's an easy and guided way of getting started managing your transfers fast and painless. Click the button below to get started and again, Welcome to Anyfile!</p>
                <a href="/">Start Sending!</a>
              </section>
            </section>
            <section v-else-if="plan">
              <section class="loading-section" v-if="loading">
                <Loading />
              </section>
              <section v-else-if="!newCard">
                <section id="term-selector">
                  <div @click="()=>changePlan(p.term)" v-for="p in plan.prices" :key="p.term" :class="{selected: selectedTerm==p.term}">
                    <article class="selection-indicator">
                      <fa-icon class="selected-plan" v-if="selectedTerm==p.term" :icon="['fas','check']"></fa-icon>
                    </article>
                    <h2>{{plan.title}} {{capitalize(p.term)}}</h2>
                    <p class="price">${{p.price}} <span class="currency">USD</span></p>
                    <p class="remarks">{{p.remarks}}</p>
                  </div>
                </section>
                <section id="order-summary">
                  <p>Summary</p>
                  <section id="payment">
                    <article>{{plan.title}} {{capitalize(selectedTerm)}}</article>
                    <article>${{selectedPrice().price}}</article>
                    <article>Amount Due{{trialAvailable() ? ' in 14 Days' : ''}}</article>
                    <article>${{selectedPrice().price}}</article>
                  </section>
                </section>
                <section id="payment-method">
                  <p>Payment Information</p>
                  <section id="methods">
                    <section>
                      <span>
                        <span class="option-container">
                          <input v-model="paymentType" name="payment-type" type="radio" value="credit-card">
                          <span class="checked-indicator"></span>
                        </span>
                        <span class="payment-title">Credit or Debit Card</span>
                      </span>
                      <span class="payment-method-image">
                        <img :src="require('@/assets/Checkout/payment-methods.svg')">
                      </span>
                      <section class="selected-payment-options" v-if="paymentType == 'credit-card'">
                        <CardSelector
                          @change="toggleAddCard" 
                          v-model="selectedCard"
                          class="card-selector"
                        />
                      </section>
                    </section>
                    <!-- <section>
                      <span>
                        <span class="option-container">
                          <input v-model="paymentType" name="payment-type" type="radio" value="google-pay">
                          <span class="checked-indicator"></span>
                        </span>
                        <span class="payment-title">Pay with Google Pay</span>
                      </span>
                      <span>
                        <img src="" alt="">
                      </span>
                      <section class="selected-payment-options" v-if="paymentType == 'google-pay'">
                        Google Pay options go here...
                      </section>
                    </section> -->
                    <!-- <section>
                      <span>
                        <span class="option-container">
                          <input v-model="paymentType" name="payment-type" type="radio" value="apple-pay">
                          <span class="checked-indicator"></span>
                        </span>
                        <span class="payment-title">Pay with Apple Pay</span>
                      </span>
                      <span>
                        <img src="" alt="">
                      </span>
                      <section class="selected-payment-options" v-if="paymentType == 'apple-pay'">
                        Apple Pay options go here...
                      </section>
                    </section> -->
                  </section>
                </section>
                <section class="checkout-btn-container">
                  <button @click="subscribe" class="checkout-btn">
                      {{ trialAvailable() ? 'Start Trial' : 'Subscribe' }}
                  </button>
                </section>
              </section>
              <section v-else>
                <AddNewCard 
                  :additionalCard="newCard"
                  :toggle="()=>toggleAddCard('add-card')" 
                  />
              </section>
            </section>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Background from '../components/Background/Background';
import { mapActions, mapGetters } from 'vuex';
import SettingsNavigation from '../components/SettingsNavigation';
import CardSelector from '../components/CardSelector';
import Loading from '../components/Loading';
import '../icons';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import toast from '../plugins/toast';
import AddNewCard from '../components/AddNewCard';
import API from '../utils/api';

export default {
  name: 'Billing',
  components: {
    Background,
    SettingsNavigation,
    Loading,
    CardSelector,
    AddNewCard
  },
  data(){
    return {
        backgroundSrc: 'Billing.svg',
        newCard: false,
        loading: false,
        selectedTerm: 'yearly',
        paymentType: 'credit-card',
        selectedCard: '',
        successfullySubscribed: null
    };
  },
  methods: {
    log(data1, data2){
      console.log(data1, data2);
    },
    toggleAddCard(value){
      if(value == 'add-card'){
        this.newCard = !this.newCard;
        this.selectedCard = '';
      }
    },
    ...mapGetters('user',['getUser','setAppUser']),
    ...mapGetters('plans',['getPlans']),
    ...mapActions('user',['signOut', 'setAppUser', 'getToken']),
    ...mapActions('invoices',['resetInvoices']),
    getError(field){
      if(!this.submitError) return false;
      if(!Array.isArray(this.submitError)) throw Error('We hit a snag! Error trying to validate form details');

      let error = this.submitError.find(e=>{
        return e.context.key === field;
      });

      if(!error) return false;
      return error.message;
    },
    handlePaymentTypeSelector(){
      switch(this.paymentType){
        case 'credit-card':
          this.log('credit-card selected');
          break;
        case 'google-pay':
          this.log('google-pay selected');
          break;
        case 'apple-pay':
          this.log('apple-pay selected');
          break;
      }
    },
    capitalize(word){
      let tmp = Array.from(word);
      return tmp.reduce((capitalized, letter, i)=>{
        if(i==0) letter = letter.toUpperCase();
        return capitalized+letter;
      }, '');
    },
    selectedPrice(){
      return this.plan.prices.find(p=>p.term==this.selectedTerm);
    },
    trialAvailable(){
      return this.user?.app.trial_available && this.plan.trial_available;
    },
    changePlan(term){
      this.selectedTerm = term;
    },
    async subscribe(){
      // Start Loading
      this.loading = true;
      // Prep Request Payload
      let payload = {
        price: this.plan.prices.find(p=>p.term == this.selectedTerm).plan_id,
        card: this.selectedCard
      };
      // Validate Payload

      // Make Request to API
      try {
        const { data: user } = await API.post('billing/subscriptions' , payload);
        toast.show(`You have been subscribed to the ${this.capitalize(this.selectedTerm)} Plan`, {type:'success'});
        this.successfullySubscribed = true;
        // Update App State
        this.setAppUser(user);
        this.resetInvoices();
        // Unset product from localStorage
        localStorage.removeItem('checkoutProduct');
      } catch(err){
        toast.show(err?.response?.data?.message || err.message, {type:'error'});
      }

      // Stop Loading
      this.loading = false;
    }
  },
  computed:{
    user(){
      return this.getUser();
    },
    plan(){
      let id = localStorage.getItem('checkoutProduct');
      if(!id) return null;
      let plans = this.getPlans();
      let plan = plans.find(p=>p.product_id==id);
      return plan;
    },
  },
  async mounted(){
    if(!this.plan) await this.$router.push('/plans');
  },
  watch: {
    selectedCard(){
      // console.log(this.selectedCard);
    }
  }
};
</script>

<style scoped>
form{
  display: grid;
  grid-gap: 10px;
}
.content{
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 20px;
  /* grid-template-rows: minmax(auto, 600px); */
}
.backdrop{
  max-height:unset;
  height: unset;
}
.backdrop > section:nth-child(1){
  display:grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
}
.signout{
  width: 110px;
  background-color: #102445;
  border-radius: 50px;
  text-transform: uppercase;
  color: #6E7174;
  border: unset;
  font-size: 13px;
  height: 35px;
  font-weight: 500;
  cursor: pointer;
  color: #FFF;
}
#term-selector{
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 140px;
  grid-gap:30px;
  padding:0 10px;
}
#term-selector > div{
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  position: relative;
}
#term-selector > div.selected{
  background-color:#448FA3;
  color:#FFF;
}
p.price {
    font-weight: 600;
    font-size: 25px;
    margin-top: 13px;
}

span.currency {
    font-size: 11px;
    position: relative;
    top: -10px;
    margin-left: -5px;
}
p.remarks {
    font-size: 12px;
    margin-top: 18px;
}
.selection-indicator{
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #F3F3F3;
  top: 5px;
  left: 5px;
}
section#payment {
    display: grid;
    grid-template-columns: 1fr 100px;
    grid-auto-rows: 29px;
    align-items: center;
    background-color: #F5F6F8;
    border-radius: 5px;
    padding: 15px 0px;
    margin-top: 10px;
}
section#payment > article:nth-child(odd) {
    text-align: left;
}
section#order-summary, section#payment-method {
    padding: 10px;
    margin-top: 15px;
}
section#payment > article{
    padding:20px;
}
section#payment > article:nth-child(1),
section#payment > article:nth-child(2){
    color:#6E7174;
    font-size:14px
}
section#payment > article:nth-child(3),
section#payment > article:nth-child(4){
    font-size:20px;
    font-weight:600 !important;
}
#order-summary, #payment-method {
    text-align: left;
}
#order-summary > p, #payment-method > p {
    font-weight: 700;
}
.selected-plan {
    color: #848484;
    font-size: 10px;
    position: relative;
    top: -3px;
}
input[type="radio"] {
    all: unset;
    width: 18px;
    height: 18px;
    border: 2px solid #CCCCDB;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left:0;
    z-index:1
}
span.checked-indicator {
    all: unset;
    width: 18px;
    height: 18px;
    border: 2px solid #CCCCDB;
    display: inline-block;
    border-radius: 50%;
    position: relative;
}

#methods input[type="radio"]:checked ~ span.checked-indicator:after {
    width: 12px;
    height: 12px;
    display: block;
    background: #ccccdb;
    content: '';
    border-radius: 50%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}
span.option-container {
    position: relative;
    top:4px;
}
section#methods {
    margin-top: 10px;
}
span.payment-title {
    margin-left: 10px;
    font-weight: 600;
    font-size: 20px;
}
section#methods > section {
    margin-bottom: 17px;
    position: relative;
}
.checkout-btn{
  width: 140px;
  background-color: #102445;
  border-radius: 50px;
  text-transform: uppercase;
  color: #6E7174;
  border: unset;
  font-size: 13px;
  height: 35px;
  font-weight: 500;
  cursor: pointer;
  color: #FFF;
  margin-left:10px;
}
.checkout-btn-container{
  text-align: left;
}
.payment-method-image{
  right: 0;
  position: absolute;
}
section.loading-section {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFF;
}
.checkout-content{
  position: relative;
}
section#checkout-success {
    width: 340px;
    margin: 0 auto;
    height: 400px;
}
section#checkout-success img {
    width: 200px;
    margin-bottom: 20px;
}

section#checkout-success h3 {
    font-size: 30px;
    margin-bottom: 15px;
}

section#checkout-success p {
    font-size: 14px;
}
section#checkout-success a {
    text-decoration: none;
    padding: 10px 15px;
    margin-top: 25px;
    display: inline-block;
    border-radius: 20px;
    background: #FF5722;
    color: #FFF;
    box-shadow: 0 0 10px rgba(255,87,34,0.5);
}
</style>