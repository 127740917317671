<template>
  <div id="about" class="main-section">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <div class="backdrop">
        <section id="about-content">
          <h1 id="title">Welcome to Anyfile</h1>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Praesentium nisi laborum inventore eveniet! Sunt aliquam voluptas beatae excepturi, rem optio in obcaecati quod illum ad, deserunt ipsum similique, fuga repellendus.</p>
          <img id="banner" src="@/assets/About/banner.svg" alt="About Anyfile - Banner Image">
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. In tempore aspernatur, minima pariatur commodi quaerat? Odio, vitae saepe? Nisi nobis dolore blanditiis iure, fuga quia sit quos ex voluptate, velit vel commodi sequi consectetur corrupti ea explicabo, laboriosam tenetur voluptatum accusamus temporibus soluta dicta? Iusto mollitia officia maiores voluptatum quam?</p>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti suscipit similique delectus voluptas maxime repellat inventore id voluptate! Dolores, nam architecto. Veniam aspernatur ea exercitationem possimus tempora. Saepe placeat ea quam deserunt, minima quaerat beatae, ratione ipsum recusandae vero reiciendis fuga pariatur suscipit ad amet minus labore tempore eum. Doloribus?</p>
        </section>
        <section class="more-info">
          <article v-for="(info, index) in moreInfo" :key="index">
            <img :src="info.icon" :alt="info.title">
            <h3>{{info.title}}</h3>
          </article>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Background from '../components/Background/Background';

export default {
  name: 'Login',
  components: {
    Background
  },
  data(){
    return {
        backgroundSrc: 'About.svg',
        moreInfo: [
          // {
          //   icon: require('@/assets/About/commitment.svg'),
          //   title: 'Commitment to the arts'
          // },
          // {
          //   icon: require('@/assets/About/newspaper.svg'),
          //   title: 'Newsroom'
          // },
          // {
          //   icon: require('@/assets/About/work-with-us.svg'),
          //   title: 'Work with us'
          // },
          {
            icon: require('@/assets/About/promote.svg'),
            title: 'Advertise'
          },
          {
            icon: require('@/assets/About/whats-new.svg'),
            title: 'What\'s new'
          },
          {
            icon: require('@/assets/About/get-in-touch.svg'),
            title: 'Get in touch'
          }
        ]
    };
  }
};
</script>

<style scoped>
/* GLOBAL OVERRIDES */
.backdrop{
  height: unset;
  max-height: unset;
}
#about-content{
  text-align: center;
  max-width:800px;
  margin:0 auto;
}
#title{
  text-align: center;
}
p{
  color:#6E7174;
  line-height: 29px;
  margin-top:10px
}
p:not(:first-of-type):not(:last-of-type){
  margin-bottom:30px
}
img#banner{
  margin:30px 0;
  width: 100%;
  max-width: 650px;
}
section.more-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 130px;
    margin-top: 50px;
    grid-gap: 25px;
}
section.more-info > article {
    box-shadow: 0 0 30px rgba(0,0,0, .06);
    border-radius: 10px;
    padding: 15px;
}
section.more-info > article > img {
    background-color: #F1F6FF;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 12px;
    height: 55px;
    width: 55px;
}
</style>