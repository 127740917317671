<template>
  <div class="main-section" id="transfer-details">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <div class="backdrop" :class="{init: loading}">
        <section v-if="transfer && Object.keys(transfer).length" :class="{hidden:loading}">
          <section>  
            <h1>{{transfer.name}}</h1>
            <p id="transfer-summary">
              <span v-if="!transfer.locked">{{`${transfer.files.length} file${(transfer.files.length > 1)?'s':''}`}} - 
              {{getFormattedSize()}} - 
              </span>
              Sent {{moment(transfer.created_at).fromNow()}}
              <span v-if="transfer.expires"> - Expires {{moment(transfer.expires).fromNow()}}</span>
            </p>
          </section>
          <section class='separator'></section>
          <section v-if="transfer.locked" class="protected-container">
            <form method="post" :action="`?pwd=${passwordEnc}`" @submit.prevent="setPassword">
              <h2>Password</h2>
              <input v-model="password" type="password">
            </form>
          </section>
          <section v-else>
            <TransferActions showLink="true" :transfer="transfer" :showPassword="true" :updateTransfer="updateTransfer" /> <!--:updateTransfer="(t)=>$router.push(`/transfers/${t.id}`)"/>-->
            <section id="transfer">
              <section class="left">
                <h3>{{`Sent to ${transfer.recipients.length} ${transfer.recipients.length > 1 ? 'People':'Person'}`}}</h3>
                <section class="people-list-item" v-for="person in transfer.recipients" :key="person.email">
                  <p>{{person.email}}</p>
                  <p class="download-count">{{(person.downloads) ? `Downloaded ${person.downloads} file${person.downloads > 1 ? 's':''} ` : 'Not yet downloaded'}}</p>
                </section>
                <section class="people-list-item">
                  <p>Link Downloads</p>
                  <p class="download-count">{{(countDownloads('link')) && `Downloaded ${countDownloads('link')} file${(countDownloads('link')!=1?'s':'')}` || 'Not yet downloaded'}}</p>
                </section>
              </section>
              <section class="right">
                <h3>{{`${transfer.files.length} File${(transfer.files.length > 1)?'s':''}`}} </h3>
                <section class="file-list-item" v-for="file in transfer.files" :key="file.id">
                  <p>{{file.name}}</p>
                  <p class="download-count">{{getFormattedSize(file.id)}}</p>
                  <FileActions :actions="['download']" :file="file" :transferID="transfer.id" :updateTransfer="updateTransfer"/>
                </section>
              </section>
            </section>
            <section class="download-total">
              <h3>Total Downloads</h3>
              <h4>{{countDownloads()}}</h4>
            </section>
          </section>
        </section>
        <section v-if="loading" class="loading"> 
          <Loading />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Background from '../components/Background/Background';
import TransferActions from '../components/TransferActions';
import FileActions from '../components/FileActions';
import Loading from '../components/Loading';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'TransferDetails',
  components: {
    Background,
    TransferActions,
    FileActions,
    Loading
  },
  data(){
    return {
        backgroundSrc: 'TransferDetails.svg',
        transfer: {},
        loading: true,
        password: '',
        passwordEnc: ''
    };
  },
  computed: {
    ...mapState(['transfers'])
  },
  methods: {
    log: console.log,
    moment,
    ...mapActions(['fetchTransfer']),
    ...mapActions('user',['getToken']),
    transferAction(type){
      console.log(type);
    },
    async getTransfer(id){
      this.loading = true;
      this.transfer = this.transfers.sent.filter(t=>t.id == id)[0] || this.transfers.received.filter(t=>t.id == id)[0];
      let pwd = this.getCookie(id);
      if(!this.transfer || (this.transfer.locked && pwd)){
        this.transfer = await this.fetchTransfer({token: await this.getToken(), id});
      }
      if(!this.transfer) this.$router.push('/404');
      this.loading = false;
    },
    getFormattedSize(id){
      let size=0;

      if(id){
        size = this.transfer.files.find(f=>f.id==id).size;
      } else {
        this.transfer.files.forEach(file=>{
          size += file.size;
        });
      }
      if(size == 0) return '0 Bytes';
      let k = 1000,
          dm = 2,
          sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(size) / Math.log(k));
      return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    countDownloads(type){
      let count=0;
      switch(type){
        case 'recipients':
          this.transfer.recipients.forEach(f=>{
            count += f.downloads;
          });
          break;
        case 'link':
          this.transfer.link_downloads.forEach(f=>{
            count += f.downloads;
          });
          break;
        default:
          this.transfer.recipients.forEach(f=>{
            count += f.downloads;
          });
          this.transfer.link_downloads.forEach(f=>{
            count += f.downloads;
          });
          break;
      }
      return count;
    },
    updateTransfer(transfer){
      this.transfer = transfer;
    },
    setPassword(){
      this.setCookie(this.$route.params.id, this.passwordEnc, 3650);
      window.location.reload();
    },
    setCookie(name, value, days) {
        var d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString();
    },
    getCookie(name){
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }
  },
  mounted(){
    this.getTransfer(this.$route.params.id);
  },
  watch: {
    password(){
      this.passwordEnc = Buffer.from(this.password, 'utf-8').toString('base64');
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.getTransfer(to.params.id);
    next();
  }
};
</script>

<style scoped>
.hidden {
    display: none !important;
}
.backdrop:not(.init){
  max-height: unset;
  height:unset;
}
.backdrop h1 {
    font-size: 54px;
    position: relative;
    font-weight: 700;
    text-align: left;
}
#transfer-details {
    text-align: left;
    position:relative;
}
section#transfer-details {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: calc(100vh - 410px);
}
.separator{
  margin-top:30px;
  margin-bottom:30px;
  border-bottom:1px solid #CCCCDB
}
section#transfer-details article {
    height: 120px;
    border: 1px solid #CCCCDB;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 20px;
    position:relative;
}
section#transfer-details p.date{
  font-size: 13px;
  margin-bottom: 6px;
}
section#transfer-details h3.title a{
  text-decoration: none;
  color: inherit;
}
.transfer-actions{
  margin-top:15px;
}
.transfer-actions span{
  font-size: 12px;
  margin-right:20px;
  cursor: pointer;
}
.transfer-summary{
  font-size: 14px;
  color: #6E7174;
}
section.people-list-item, section.file-list-item {
    margin-top: 15px;
}
section.file-list-item {
    position: relative;
    padding-right: 40px;
}
section#transfer {
    margin-top: 35px;
    border: 1px solid #CCCCDB;
    padding: 20px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
}
p.download-count {
    font-size: 11px;
    margin-top: 4px;
    color: #6E7174;
}
section.download-total {
    text-align: center;
    margin-top: 25px;
}
section.download-total h4 {
    font-size: 80px;
    font-weight: 500;
    color: #ACDBDF;
}
.loading{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.protected-container h2{
  font-size: 35px;
  margin-top: 80px;
}
.protected-container input {
    width: 290px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    font-size: 35px;
}

.protected-container {
    text-align: center;
}
</style>