<template>
  <div class="login">
    <form action="" v-on:submit.prevent="signIn">
    <div id="login-top-section">
        <h3>Sign in to Transfer</h3>
        <section id="login-providers">
          <img @click="signInFacebook()" :src="icons.FacebookLogin" alt="Facebook Login">
          <img @click="signInTwitter()" :src="icons.TwitterLogin" alt="Twitter Login">
          <img @click="signInGoogle()" :src="icons.GoogleLogin" alt="Google Login">
          <!-- <img src="../assets/social/LinkedInLogin.svg" alt="LinkedIn Login"> -->
        </section>
        <p>or use your email account</p>
    </div>
    <div class="error" v-if="getError()">
      <p>{{getError().message}}</p>
    </div>
    <div id="login-fields">
        <input v-model="user.email" type="text" name="to" placeholder="Email">
        <input v-model="user.password" type="password" name="topic" placeholder="Password">
    </div>
    <a id="forgotpwd-link" href="/forgotpwd">Forgot your password?</a>
    <div id="login-buttons">
        <button type="submit">Sign In</button>
    </div>
    </form>
    <p class="signup-link">Don't have an account yet? <router-link to="/signup">Start a free trial!</router-link></p>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FacebookLogin from '@/assets/Social/FacebookLogin.svg';
import TwitterLogin from '@/assets/Social/TwitterLogin.svg';
import GoogleLogin from '@/assets/Social/GoogleLogin.svg';
export default {
  data(){
    return {
      icons: {
        FacebookLogin,
        TwitterLogin,
        GoogleLogin
      },
      user: {
        email: '',
        password: ''
      }
    };
  },
  methods: {
    ...mapActions('user', ['signInGoogle','signInFacebook','signInTwitter','signInEmail']),
    ...mapGetters('user', ['getError']),
    signIn() {
      this.signInEmail(this.user);
    },
    parseError(){
      
    }
  }
};
</script>

<style>
#page-content{
  height:100%
}
.login {
    max-width: 500px;
    width:100%;
    min-width:300px;
    min-height: 520px;
    margin: 0 auto;
    background-color: #FFF;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 80px;
    transform: translateY(-50%);
    box-shadow: 0 5px 35px rgba(0,0,0,.1);
    margin-bottom: 20px;
    padding-bottom: 20px;
}
@media (max-height:700px){
  .login {
    transform: unset !important;
    top: 30px;
  }
  #login {
    min-height: 550px;
  }
}
#login-folder-icon {
  text-align: left;
}

#login-top-section {
    color: #222222;
    padding-top: 50px;
    margin-bottom:35px;
}

#login-top-section h3 {
  font-size:35px;
}

#login-providers img{
  margin:10px 7px;
  cursor: pointer;
}

#login-top-section p{
  font-size:18px;
  color:#777777;
}

#login-fields > *{
  display:block;
  width:80%;
  margin:20px auto;
  height:46px;
  background-color:#F1F2F6;
  border-radius:5px;
  border:unset;
  padding:20px;
  font-family: ProximaNova;
  font-size: 15px;
}
#login-fields > *::placeholder{
  color:#AAAAAA;
}

#forgotpwd-link{
  font-size:18px;
  color:#222222;
  display: block;
  text-align: center;
  text-decoration: none;
  font-weight:600;
  margin-bottom:30px;
  margin-top:30px;
}

#login-buttons button{
  width:180px;
  background-color:#102445;
  border-radius:50px;
  text-transform: uppercase;
  color:#FFF;
  border:unset;
  font-size: 15px;
  height:50px;
  font-weight: 600;
  cursor: pointer;
}

.error {
    background-color: #ba2304;
    margin: -20px auto 0;
    width: 80%;
    padding: 12px;
    color: #FFF;
    border-radius: 5px;
    font-weight: 600;
    line-height: 21px;
    word-break: break-all;
}
p.signup-link {
    margin-top: 18px;
}
p.signup-link a {
    color: #d47867;
}
</style>