<template>
  <div class="main-section" id="billing">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <div class="backdrop">
        <section>
          <h1>Personal Details</h1>
          <button class="signout" @click="signOut()">Sign out</button>
        </section>
        <section class='separator'></section>
        <section class="content">
          <SettingsNavigation class="setting-nav"/>
          
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Background from '../components/Background/Background';
import { mapActions } from 'vuex';
import SettingsNavigation from '../components/SettingsNavigation';

export default {
  name: 'Account',
  components: {
    Background,
    SettingsNavigation
  },
  data(){
    return {
        backgroundSrc: 'Billing.svg'
    };
  },
  methods: mapActions(['signOut'])
};
</script>

<style scoped>
.content{
  display:grid;
  grid-template-columns:200px 1fr;
  grid-gap:20px
}
.backdrop > section:nth-child(1){
  display:grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
}
.signout{
  width: 110px;
  background-color: #102445;
  border-radius: 50px;
  text-transform: uppercase;
  color: #6E7174;
  border: unset;
  font-size: 13px;
  height: 35px;
  font-weight: 500;
  cursor: pointer;
  color: #FFF;
}
</style>