<template>
  <div id="login" class="main-section">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <SignupForm />
    </div>
  </div>
</template>

<script>
import Background from '../components/Background/Background';
import SignupForm from '../components/SignupForm';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Signup',
  components: {
    Background,
    SignupForm
  },
  data(){
    return {
        backgroundSrc: 'Signup.svg'
    };
  },
  methods: {
    ...mapActions('user', ['signInGoogle']),
    ...mapGetters('user', ['isAuth'])
  }
};
</script>

<style>

</style>