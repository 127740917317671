<template>
    <section id="add-new-card-container">
        <section class="loading-section" v-if="loading">
            <Loading />
        </section>
        <form action="POST" @submit.prevent="updateBilling">
            <span>
            <label v-if="getError('name')" for="name" class="error-message">{{getError('name')}}</label>
            <input :class="{'validation-error':getError('name')}" v-model="billing.name" type="text" name="name" placeholder="Name on Card">
            </span>
            <span>
            <label v-if="getError('street_address')" for="street_address" class="error-message">{{getError('street_address')}}</label>
            <input :class="{'validation-error':getError('street_address')}" v-model="billing.street_address" type="text" name="street_address" placeholder="Street Address">
            </span>
            <span>
            <label v-if="getError('city')" for="city" class="error-message">{{getError('city')}}</label>
            <input :class="{'validation-error':getError('city')}" v-model="billing.city" type="text" name="city" placeholder="City">
            </span>
            <span>
            <label v-if="getError('state')" for="state" class="error-message">{{getError('state')}}</label>
            <input :class="{'validation-error':getError('state')}" v-model="billing.state" type="text" name="state" placeholder="State">
            </span>
            <span>
            <label v-if="getError('country')" for="country" class="error-message">{{getError('country')}}</label>
            <input :class="{'validation-error':getError('country')}" v-model="billing.country" type="text" name="country" placeholder="Country">
            </span>
            <span>
            <label v-if="getError('vat')" for="vat" class="error-message">{{getError('vat')}}</label>
            <input :class="{'validation-error':getError('vat')}" v-model="billing.vat" type="text" name="vat" placeholder="VAT">
            </span>
            <span>
            <label v-if="getError('card')" for="card" class="error-message">{{getError('card')}}</label>
            <card 
                class='stripe-card'
                :class="{ complete, 'validation-error':getError('card') }"
                :stripe="stripe_pk"
                :options='stripeOptions'
                @change='complete = $event.complete'
            />
            </span>
            <span class="form-actions">
            <button type="submit">Update Billing</button>
            <button v-if="additionalCard" class="cancel" @click="toggle">Cancel</button>
            </span>
        </form>
    </section>
</template>

<script>
import { Card, createToken } from 'vue-stripe-elements-plus';
import Joi from 'joi';
import api from '../utils/api';
import toast from '../plugins/toast';
import { mapActions } from 'vuex';
import Loading from '../components/Loading';

export default {
    props:['toggle', 'additionalCard'],
    data(){
        return {
            stripe_pk: process.env.VUE_APP_STRIPE_PK,
            stripeOptions: {
                // see https://stripe.com/docs/stripe.js#element-options for details
                style: {
                    base: {
                    fontSize:'17px',
                    fontWeight:300,
                    fontFamily: 'Helvetica',
                        '::placeholder': {
                            color: '#AAA',
                        },
                    },
                },
            },
            billing: {
                name: '',
                street_address: '',
                city: '',
                state: '',
                country: '',
                vat: '',
            },
            billingSchema: Joi.object({
                name: Joi.string().regex(/^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/i).required().messages({
                    'string.pattern.base': 'Must provide a first and last name',
                    'string.pattern.invert.base': 'Must provide a first and last name',
                    'string.pattern.invert.name': 'Must provide a first and last name',
                    'string.pattern.name': 'Must provide a first and last name',
                }),
                street_address: Joi.string().required(),
                city: Joi.string().allow(null, ''),
                state: Joi.string().allow(null, ''),
                country: Joi.string().required(),
                vat: Joi.string().allow(null, ''),
            }).messages({
                'string.empty':'This field is required'
            }),
            submitError: null,
            loading: false,
            complete: false,
        };
    },
    components: {
        Card,
        Loading
    },
    methods: {
        ...mapActions('user',['getToken','setAppUser']),
        getError(field){
            if(!this.submitError) return false;
            if(!Array.isArray(this.submitError)) throw Error('We hit a snag! Error trying to validate form details');

            let error = this.submitError.find(e=>{
                return e.context.key === field;
            });

            if(!error) return false;
            return error.message;
        },
        setCardError(err){
            this.submitError = [
                {
                context: {
                    key: 'card'
                },
                message: err.message,
                },
            ];
        },
        async updateBilling () {
            let validation = this.billingSchema.validate({
                ...this.billing
            },
            {abortEarly:false});

            if(validation.error){
                this.submitError = validation.error.details;
                return;
            }
            this.submitError = null;
            try{
                this.loading = true;
                createToken({
                    name: this.billing.name,
                    address_line1: this.billing.street_address,
                    address_city: this.billing.city,
                    address_state: this.billing.state,
                    address_country: this.billing.country,
                })
                .then(async data =>{
                    if(!data.token){
                        if(data.error){
                            this.setCardError({message:data.error.message});
                        } else {
                            this.setCardError({message:'Error contacting the stripe api'});
                        }
                        this.loading = false;
                        return;
                    }
                    
                    let { data: user } = await api.post('billing/method', {token:data.token.id});
                    
                    this.billing.name = '';
                    this.billing.street_address = '';
                    this.billing.city = '';
                    this.billing.state = '';
                    this.billing.country = '';

                    this.setAppUser(user);
                    this.toggle();
                    this.loading = false;
                    toast.show('Payment method added successfully.', {type:'success'});
                })
                .catch(err=>{
                    toast.show('We encountered an issue while adding the payment method.', {type:'error'});
                    this.setCardError(err);
                    this.loading = false;
                });
            } catch(err){
                this.setCardError(err);
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
form{
  display: grid;
  grid-gap: 10px;
}
.form-actions{
  text-align: left;
}
button[type="submit"]{
  all: unset;
  text-align: center;
  width:150px;
  background-color: #102445;
  border-radius: 50px;
  text-transform: uppercase;
  color: #6E7174;
  border: unset;
  font-size: 13px;
  height: 35px;
  font-weight: 500;
  cursor: pointer;
  color: #FFF;
}
button.cancel {
    all: unset;
    text-align: center;
    width: 150px;
    background-color: #b6b6b6;
    border-radius: 50px;
    text-transform: uppercase;
    color: #6E7174;
    border: unset;
    font-size: 13px;
    height: 35px;
    font-weight: 500;
    cursor: pointer;
    color: #FFF;
    margin-left: 15px;
}
input[type="text"] {
    width:100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #CCCCDB;
    color: #102445;
    padding: 15px;
    font-size: 16px;
}
input[type="text"]::placeholder {
    color: #AAAAAA;
}
.stripe-card {
  border: 1px solid #CCCCDB;
  padding:9px 15px;
  height:40px;
  border-radius:5px;
}
.stripe-card.complete {
  border-color: #7bd2b4;
}
.validation-error {
    border-color: red !important;
}
label.error-message {
    color: red;
    display: block;
    text-align: left;
    margin-bottom: 5px;
}
section.loading-section {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFF;
    z-index: 1;
}
#add-new-card-container{
    position: relative;
}
</style>