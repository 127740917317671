<template>
  <div class="main-section" id="transfers">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <div class="backdrop">
        <section>  
          <h1>Transfers</h1>
          <div id="transfer-search">
            <input v-model="search" type="text" placeholder="Search by file name or email">
            <fa-icon class="search-icon" :icon="['fas','search']"></fa-icon>
          </div>
        </section>
        <section id="filters">
          <button id="sent" :class="{active:type=='sent'}" @click="setType('sent')">Sent</button>
          <button id="received" :class="{active:type=='received'}" @click="setType('received')">Received</button>

          <section class="sorting">
            Sort by: 
            <select name="sorter" v-model="sort">
              <option value="date">DATE</option>
              <option value="title">TITLE</option>
            </select>
          </section>
        </section>
        <section class='separator'></section>
        <section id="transfers-list">
          <article v-for="(transfer, index) in transfers[type]" :key="transfer.key">
            <p v-if="index == transfers[type].length-1" v-view="paginate" class="date">{{moment(transfer.created_at).format('ll')}}</p>
            <p v-else class="date">{{moment(transfer.created_at).format('ll')}}</p>
            <h3 class="title"><router-link :to="`/transfers/${transfer.id}`">{{transfer.name}}</router-link></h3>
            <router-link :to="`/transfers/${transfer.id}`" class="transfer-arrow-link"><fa-icon class="transfer-arrow" :icon="['fas','chevron-right']" /></router-link>
            <TransferActions :transfer="transfer" :showDownload="true" :showPassword="true"/>
          </article>
          <article v-if="loading" class="loading">
            <Loading /> 
          </article>
          <NoTransfersFound :type="type" v-if="!transfers[type].length && !loading"/>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Background from '../components/Background/Background';
import TransferActions from '../components/TransferActions';
import Loading from '../components/Loading';
import NoTransfersFound from '../components/NoTransfersFound';
import moment from 'moment';
import '../icons';
import { mapState, mapActions } from 'vuex';
import Vue from 'vue';
import checkView from 'vue-check-view';
Vue.use(checkView);

export default {
  name: 'Transfers',
  components: {
    Background,
    TransferActions,
    Loading,
    NoTransfersFound
  },
  data(){
    return {
        search: '',
        sort: 'date',
        backgroundSrc: 'Transfers.svg',
        type: 'sent',
        loading:true
    };
  },
  computed: {
    ...mapState(['transfers'])
  },
  methods: {
    ...mapActions(['fetchSentTransfers','fetchReceivedTransfers']),
    ...mapActions('user',['getToken']),
    setType(type){
      this.type = type;
    },
    moment,
    async getSentTransfers({reset} = {}){
      this.loading = true;
      let token = await this.getToken();
      await this.fetchSentTransfers({token, sort: this.sort, search: this.search, page: ((reset) ? '1' : this.transfers.pagination.sent.nextPage)});
      this.loading = false;
    },
    async getReceivedTransfers({reset} = {}){
      this.loading = true;
      let token = await this.getToken();
      await this.fetchReceivedTransfers({token, sort: this.sort, search: this.search, page: ((reset) ? '1' : this.transfers.pagination.received.nextPage)});
      this.loading = false;
    },
    paginate(e){
      if(e.type != 'enter') return;
      if(this.transfers.pagination[this.type].nextPage){
        switch(this.type){
          case 'sent':
            this.getSentTransfers();
            break;
          case 'received':
            this.getReceivedTransfers();
            break;
          default:
            break;
        }
      } else {
        this.loading = false;
      }
    }
  },
  mounted(){
    this.getSentTransfers();
  },
  watch: {
    sort: function(){
      switch(this.type){
        case 'sent':
          this.getSentTransfers({reset:true});
          break;
        case 'received':
          this.getReceivedTransfers({reset:true});
          break;
        default:
          break;
      }
    },
    type: function(){
      switch(this.type){
        case 'sent':
          this.getSentTransfers({reset:true});
          break;
        case 'received':
          this.getReceivedTransfers({reset:true});
          break;
        default:
          break;
      }
    },
    search: function(){
      if(this.search.length < 3 && this.search.length != 0) return;
      switch(this.type){
        case 'sent':
          this.getSentTransfers({reset:true});
          break;
        case 'received':
          this.getReceivedTransfers({reset:true});
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
/* GLOBAL OVERRIDES */
.backdrop{
  max-height:unset;
  height: unset;
}
.separator{
  margin-top:15px;
}

/* Local Styles */
#transfer-search input{
  width: 40%;
  height: 45px;
  padding: 15px;
  position: absolute;
  top: 65px;
  right: 100px;
  border-radius: 5px;
  border: 1px solid #CCCCDB;
}
#transfers-list {
    text-align: left;
    position:relative;
}
#filters{
  text-align: left;
  position:sticky;
  top:0;
  padding-bottom:15px;
  background:#FFF;
  z-index:1;
}
section#transfers-list {
    z-index:0
}
#filters button{
  width: 110px;
  background-color: #FFF;
  border-radius: 50px;
  text-transform: uppercase;
  color: #6E7174;
  border: unset;
  font-size: 15px;
  height: 45px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 30px;
}
#filters button.active{
  background-color: #102445;
  color: #FFF;
}
section#transfers-list article {
    height: 120px;
    border: 1px solid #CCCCDB;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 20px;
    position:relative;
}
section#transfers-list article.loading{
  border:unset;
}
section#transfers-list p.date{
  font-size: 13px;
  margin-bottom: 6px;
}
section#transfers-list h3.title a{
  text-decoration: none;
  color: inherit;
}
.transfer-arrow{
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}
.transfer-arrow-link{
  text-decoration: none;
  color:inherit;
}
.search-icon{
  position:absolute;
  right:115px;
  top:80px;
  color: #0F2748;
}
section.sorting {
    position: absolute;
    right: 0;
    top: 42px;
}

section.sorting select {
    border: unset;
    font-size: 16px;
    font-weight: 100;
    border-bottom: 1px solid #102545;
}

section.sorting select:focus {
    outline: unset;
}
</style>