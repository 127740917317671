<template>
  <div class="main-section" id="invocies">
    <Background v-bind:backgroundSrc="backgroundSrc"/>
    <div id="page-content">
      <div class="backdrop">
        <section>
          <h1>Invoices</h1>
          <button class="signout" @click="signOut()">Sign out</button>
        </section>
        <section class='separator'></section>
        <section class="content">
          <section>
            <SettingsNavigation class="setting-nav"/>
          </section>
          <section>
            <section class="invoice" v-for="invoice in invoices.data" :key="invoice.id">
              <font class="invoice-number">Invoice #{{invoice.number}}</font>
              <section class="invoice-minimized-details">
                <section v-view="paginate"><a class="invoice-download-link" :href="invoice.invoice_pdf" download target="_blank">#{{invoice.number}}</a></section>
                <section>{{moment(new Date(invoice.period_start*1000)).format('ll')}}</section>
                <section><button class="open-invoice-summary" @click="()=>toggleDetails(invoice.id)">{{detailsVisible(invoice.id) ? 'Hide Details' : 'View Details'}}</button></section>
              </section>
              <section class="invoice-summary" v-if="detailsVisible(invoice.id)">
                <h3>Summary</h3>
                <div class="summary-container">
                  <article>
                    <p>Payment to Anyfile</p>
                    <p>Amount Paid</p>
                  </article>
                  <article>
                    <p>{{dollarFormat(invoice.amount_due)}}</p>
                    <p>{{dollarFormat(invoice.amount_paid)}}</p>
                  </article>
                  <article>
                    <p>Status</p>
                    <p>{{capitalize(invoice.status.replace('_',' '))}}</p>
                  </article>
                </div>
              </section>
            </section>
            <section id="invoices-loading" v-if="loading">
              <Loading/>
            </section>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Background from '../components/Background/Background';
import { mapActions, mapState } from 'vuex';
import SettingsNavigation from '../components/SettingsNavigation';
// import API from '../utils/api';
import moment from 'moment';
import Vue from 'vue';
import checkView from 'vue-check-view';
import Loading from '../components/Loading';
Vue.use(checkView);

export default {
  name: 'Invoices',
  components: {
    Background,
    SettingsNavigation,
    Loading
  },
  data(){
    return {
        backgroundSrc: 'Billing.svg',
        invoiceDetailsVisibility: [],
        loading:true
    };
  },
  methods: {
    ...mapActions('user',['signOut']),
    ...mapActions('invoices',['fetchInvoices']),
    moment,
    paginate(e){
      if(e.type != 'enter') return;
        this.getInvoices();
    },
    async getInvoices(){
      this.loading = true;
      await this.fetchInvoices();
      this.loading = false;
    },
    toggleDetails(id){
      if(this.invoiceDetailsVisibility.includes(id))
        this.invoiceDetailsVisibility = this.invoiceDetailsVisibility.filter(i=>i!=id);
      else
        this.invoiceDetailsVisibility.push(id);
    },
    detailsVisible(id){
      return this.invoiceDetailsVisibility.includes(id);
    },
    dollarFormat(number){
      let money = (number/100).toFixed(2);
      return `$${money}`;
    },
    capitalize(phrase){
      return phrase.split(' ').map(w=>{
        return `${w[0].toUpperCase()}${w.slice(1)}`;
      }).join(' ');
    }
  },
  async mounted(){
    // let {data:{data:invoices}} = await API.get('billing/invoices');
    // this.invoices = invoices;
    this.getInvoices();
  },
  computed: {
    ...mapState(['invoices'])
  }
};
</script>

<style scoped>
.content{
  display:grid;
  grid-template-columns:200px 1fr;
  grid-gap:20px
}
.backdrop{
  max-height:unset;
  height: unset;
}
.backdrop > section:nth-child(1){
  display:grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
}
.signout{
  width: 110px;
  background-color: #102445;
  border-radius: 50px;
  text-transform: uppercase;
  color: #6E7174;
  border: unset;
  font-size: 13px;
  height: 35px;
  font-weight: 500;
  cursor: pointer;
  color: #FFF;
}

section.invoice {
    text-align: left;
    margin-bottom: 25px;
    padding-bottom: 25px;
}

font.invoice-number {
    color: #6E7174;
}

section.invoice:not(:nth-last-child(1)) {
    border-bottom: 1px solid #e5e5ec;
}

section.invoice-minimized-details {
    display: grid;
    grid-template-columns: 1fr 1fr 116px;
    margin-top: 15px;
    border: 1px solid #e5e5ec;
    padding: 15px;
    border-radius: 5px;
    align-items: center;
}
button.open-invoice-summary {
    border: 1px solid #102545;
    background: #FFF;
    padding: 8px 11px;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
}
.summary-container {
    background-color: #F5F6F8;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 140px;
    padding: 15px;
    grid-gap: 10px;
    margin-top: 10px;
}

.summary-container > article > p:nth-child(1) {
    margin-bottom: 10px;
    color: #6E7174;
}

section.invoice-summary {
    margin-top: 10px;
}

.summary-container > article > p:nth-child(2) {
    font-size: 23px;
    font-weight: 600;
}
section.invoice-minimized-details section:nth-child(3) {
    text-align: right;
}
a.invoice-download-link {
    color: #112545;
    text-decoration: none;
    font-weight: 600;
}
#invoices-loading{
  height:115px;
}
</style>